import React from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Breadcrumbs from "../../components/ui/breadcrumbs"

import pillar from "../../../static/images/pillars/pillar-4.jpg"

class RootIndex extends React.Component {
    render() {
        return (
            <Layout location={this.props.location}>
                <Seo title="Pillar 4" />
                <div className="container single-pillar">
                    <img src={pillar} alt="ola training" />
                    <Breadcrumbs loc="pillars" subloc="pillar 4" />
                    <h1>Pillar 4</h1>
                    <h2>
                        Delay the onset of chronic disease and ailments or
                        manage existing conditions
                    </h2>
                    <p>
                        If you have a chronic condition, regular exercise can
                        help you manage symptoms and improve your health.
                        Aerobic exercise can help improve your heart health and
                        endurance and aid in weight loss. Strength training can
                        improve muscle strength and endurance, make it easier to
                        do daily activities, slow disease related declines in
                        muscle strength and provide stability to joints.
                        Flexibility exercises may help you to have optimal range
                        of motion about your joints, so they can function best,
                        and stability exercises may reduce risk of falls.
                    </p>

                    <h3>Heart disease</h3>
                    <p>
                        Regular exercise can help improve your heart health.
                        Recent studies have shown that interval training is
                        often tolerated in people with heart disease and it can
                        produce significant benefits. For people with high blood
                        pressure, exercise can lower your risk of dying of heart
                        disease and lower the risk of hearth disease
                        progressing.
                    </p>

                    <h3>Diabetes</h3>
                    <p>
                        Regular exercise can help insulin more effectively lower
                        your blood sugar level. Physical activity can also help
                        you control your weight and boost your energy. If you
                        have type 2 diabetes, exercise can lower your risk of
                        dying of heart disease.
                    </p>

                    <h3>Asthma</h3>
                    <p>
                        Often, exercise can help control the frequency and
                        severity of asthma attacks.
                    </p>

                    <h3>Back Pain</h3>
                    <p>
                        Regular low impact aerobic activities can increase
                        strength and endurance in your back and improve muscle
                        function. Abdominal and back muscle exercises (core
                        strengthening exercises) may help reduce symptoms by
                        strengthening the muscles around your spine. Stretching,
                        reduces muscle tension and improves mobility.
                    </p>

                    <h3>Arthritis</h3>
                    <p>
                        Exercise can reduce pain, help maintain muscle strength
                        in affected joints and reduce joint stiffness and
                        inflammation. It can also improve physical function and
                        quality of life for people who live with arthritis.
                    </p>

                    <h3>Cancer</h3>
                    <p>
                        According to the American Cancer Society Guidelines on
                        Nutrition and Physical Activity for Cancer Prevention,
                        getting more physical activity is associated with a
                        lower risk for several types of cancer, including
                        breast, prostate, colon and possibly pancreatic cancer.
                        Exercise can improve the quality of life for people
                        who’ve had cancer. It should be embedded as a part of
                        standard practice in cancer care and viewed as an
                        adjunct therapy that helps counteract the adverse
                        effects of cancer and its treatment.
                    </p>

                    <h3>Dementia and Alzheimer’s</h3>
                    <p>
                        Regular physical activity benefits the brain. Studies
                        show that people who are physically active are less
                        likely to experience a decline in their mental function
                        and have a lowered risk of developing Alzheimer’s
                        disease. Physical activity is one of the known
                        modifiable risk factors for dementia.{" "}
                    </p>
                    <p>
                        Exercise can improve cognition in people with dementia
                        and people who exercise regularly are at less risk of
                        dementia and cognitive impairment.{" "}
                    </p>
                    <Link className="button" to="/pillars/pillar-5/">
                        Next pillar
                    </Link>
                </div>
            </Layout>
        )
    }
}

export default RootIndex
